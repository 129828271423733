@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap";
@font-face {
  font-family: Inter;
}

@font-face {
  font-family: spartan;
  src: url("Spartan-Regular.f0c7243c.ttf");
  font-display: swap;
}

:root {
  --primario: #5b3587;
  --violeta: #5b3587;
  --gris-oscuro: #3b3b3b;
  --gris-medio: #ccc;
  --sombra: #bbc;
  --fondo-gris: #f8f8f8;
}

.bg-violeta {
  background-color: var(--violeta);
}

body {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 100px 0 0;
  position: relative;
}

img {
  max-width: 100%;
}

header {
  width: 100%;
  z-index: 10;
  background-color: #fff;
  font-family: spartan;
}

header .social-links {
  width: 100%;
  display: block;
}

header nav ul, header .social-links, header .lang {
  display: none;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
}

header .menu-button {
  padding: 5px;
}

header .menu-button img {
  width: 30px;
}

header nav {
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

.hero {
  width: 180%;
  margin-left: -85%;
}

.nosotres {
  font-family: Inter;
}

.nosotres h1 {
  text-align: center;
  color: #565887;
  font-weight: 600;
}

.nosotres .historia {
  flex-direction: column-reverse;
  justify-content: center;
  padding: 0 0 175px;
  display: flex;
  position: relative;
}

.nosotres .historia div {
  z-index: 1;
}

.nosotres .historia .text {
  color: #333;
  padding: 20px;
}

.nosotres .historia .image img {
  width: 90%;
}

.nosotres .historia .text p {
  padding: 10px 0;
  line-height: 27px;
}

.nosotres .historia .historia-frame {
  z-index: 0;
  position: absolute;
  top: -30px;
  right: 0;
}

.nosotres .somos-cooperativa {
  color: #fff;
  background: #5b3587;
  flex-direction: column;
  display: flex;
  position: relative;
}

.nosotres .somos-cooperativa .video {
  height: 200px;
  position: relative;
}

.nosotres .somos-cooperativa .video iframe {
  -webkit-box-shadow: 5px 5px 8px 3px var(--sombra);
  box-shadow: 5px 5px 8px 3px var(--sombra);
  width: 90%;
  height: 300px;
  border-radius: 30px;
  margin: auto;
  position: absolute;
  top: -130px;
  left: 0;
  right: 0;
}

.nosotres .somos-cooperativa .text {
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  line-height: 27px;
  display: flex;
}

.nosotres .somos-cooperativa h2 {
  font-size: 35px;
  display: none;
}

.nosotres .somos-cooperativa h2:first-child {
  text-align: center;
  color: #512d7d;
  font-size: 25px;
  display: block;
  position: absolute;
  top: -200px;
}

.nosotres .somos-cooperativa .text p {
  padding: 0;
  font-size: 18px;
  line-height: 30px;
}

.nosotres .somos-cooperativa .somos-cooperativa-frame {
  z-index: 0;
  display: none;
  position: absolute;
  bottom: -100px;
  right: 0;
}

.nosotres .compromiso-con-la-comunidad {
  justify-content: center;
  display: flex;
}

.nosotres .compromiso-con-la-comunidad .text {
  text-align: left;
  color: #333;
  padding: 20px;
  line-height: 30px;
}

.software-libre {
  background-color: #4b5d9f;
  padding: 30px;
  position: relative;
}

.software-libre div {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 30px;
  line-height: 25px;
}

.software-libre div h2 {
  color: #217ab2;
}

.software-libre div button {
  background: #f09916;
  border: none;
  border-radius: 25px;
  margin-top: 20px;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 700;
}

.servicios, .tecnologias, .proyectos {
  color: #512d7d;
  font-family: Inter;
}

.servicios h1, .tecnologias h1, .proyectos h1 {
  text-align: center;
}

.servicios p, .proyectos h3, .proyectos p {
  color: #333;
}

.servicios .grid {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.servicios .grid div {
  width: 50%;
}

.tecnologias {
  text-align: center;
  background: #eeefe8;
  padding: 20px 0;
  font-size: 12px;
}

.tecnologias .grid {
  color: #333;
  flex-flow: wrap;
  justify-content: center;
  display: flex;
  position: relative;
}

.tecnologias .grid div {
  width: 70px;
}

.proyectos .grid div {
  padding: 20px;
}

.proyectos .proyecto-img, .proyectos .proyecto-img div {
  padding: 0;
}

.plus-group {
  text-align: center;
}

.algunos-clientes .images {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.algunos-clientes .images div {
  width: 50px;
  background: #f4eeee;
  border-radius: 15px;
  margin: 10px;
  padding: 55px;
  position: relative;
}

.algunos-clientes .images div img {
  margin: auto;
  position: absolute;
  inset: 0;
}

.algunos-clientes {
  padding: 15px;
}

.proyectos .grid div p {
  max-width: 600px;
  font-size: 18px;
  line-height: 30px;
}

.proyectos .grid div h3 {
  font-size: 20px;
}

.proyectos .grid .tecnologias-usadas {
  display: none;
}

.contacto {
  background: linear-gradient(#ee7d5a 0% 60%, #fff 60% 100%);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.button {
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 20px;
  margin-top: 20px;
  padding: 8px 20px;
  font-family: Inter;
  font-size: .75rem;
  font-weight: 500;
  text-decoration: none;
}

.formulario-contacto {
  box-shadow: 0px 5px 4px -1px var(--sombra);
  color: var(--violeta);
  width: 60%;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: Inter;
  font-size: .75em;
  font-weight: 500;
  line-height: 160%;
}

.formulario-contacto h2 {
  margin-left: 1.25em;
  font-size: 1.125rem;
  font-weight: 700;
}

.formulario-contacto > * {
  margin: 0 2em;
}

.formulario-contacto > :first-child {
  margin-top: 1em;
}

.formulario-contacto > :last-child {
  margin-bottom: 1.5em;
}

.formulario-contacto button:last-child {
  margin-left: auto;
}

.seccion-blog {
  color: #fff;
  text-align: right;
  width: 60%;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3em;
  margin-bottom: 1em;
  display: flex;
}

.seccion-blog > * {
  margin: 0 0 2em;
}

.seccion-blog p {
  font-family: Inter;
  font-size: .81em;
  font-weight: 500;
}

.seccion-blog h1 {
  margin-bottom: 1rem;
  font-size: 1.56rem;
  font-weight: 700;
}

.contacto form {
  flex-direction: column;
  display: flex;
}

.seccion-direccion-desktop {
  display: none;
}

.seccion-direccion-mobile {
  color: var(--violeta);
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
  font-family: Inter;
  font-size: .75em;
  font-weight: 500;
}

.contacto-first {
  justify-content: space-around;
  display: flex;
}

@media only screen and (min-width: 600px) {
  .contacto {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .contacto .contacto-first {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 6em;
    display: flex;
  }

  .formulario-contacto {
    width: 30%;
    order: 2;
  }

  .seccion-blog {
    width: auto;
  }

  .seccion-direccion-mobile {
    display: none;
  }

  .seccion-direccion-desktop {
    text-align: right;
    color: var(--violeta);
    text-align: rigth;
    font-family: Inter;
    font-size: .75em;
    font-weight: 500;
    display: flex;
  }
}

.modal-backdrop {
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #0006;
  animation-delay: 3.5s;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-backdrop.opened {
  visibility: visible;
}

.modal-dialog {
  width: 50%;
  background-color: var(--fondo-gris);
  box-shadow: 0px 5px 4px -1px var(--sombra);
  z-index: 110;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  margin: -100em auto auto;
  padding: 1em;
  transition: all .7s;
  display: flex;
}

.opened > .modal-dialog {
  margin-top: 4em;
}

.modal-dialog h2 {
  margin: 0;
}

.modal-dialog .close {
  color: var(--gris-medio);
  margin-left: auto;
  font-size: 2.1em;
  font-weight: bolder;
}

.modal-dialog .close:hover, .modal-dialog .close:focus {
  color: #000;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .hero {
    width: 100%;
    margin: 0;
  }

  header nav ul, header .social-links {
    display: block;
  }

  header {
    width: 100%;
    position: fixed;
    top: 0;
  }

  header .menu-button {
    display: none;
  }

  header nav .logo-container img {
    height: auto;
  }

  header nav {
    justify-content: space-between;
    padding: 20px;
    display: flex;
  }

  .nosotres .somos-cooperativa .video iframe {
    width: 100%;
    height: 120%;
    position: absolute;
    top: -175px;
  }

  .nosotres .somos-cooperativa {
    padding: 0 150px 100px;
  }

  .nosotres .historia .text {
    text-align: right;
    width: 40%;
  }

  header .social-links div {
    justify-content: flex-end;
    padding-top: 20px;
    padding-right: 40px;
    display: flex;
  }

  header .social-links div img {
    width: 18px;
    padding: 0 18px;
  }

  header nav .logo-container img {
    padding-top: 5px;
    padding-left: 50px;
  }

  header ul {
    min-width: 500px;
    margin-top: 40px;
    display: none;
  }

  header ul li {
    display: inline-block;
  }

  header ul li a {
    color: #512d7d;
    padding: 10px;
    text-decoration: none;
  }

  .lang {
    height: -moz-fit-content;
    height: fit-content;
    width: 90px;
    min-width: 90px;
    cursor: pointer;
    margin-top: 32px;
    margin-right: 70px;
    font-size: 13px;
    position: relative;
  }

  .lang div {
    width: 15px;
    height: 20px;
    border: 1px solid #512d7d;
    border-radius: 0 15px 15px 0;
    margin: 0;
    padding: 5px 15px;
    position: absolute;
    top: 0;
    left: 50%;
  }

  .lang div span {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    position: absolute;
    inset: 0;
  }

  .lang div.selected {
    color: #fff;
    background: #512d7d;
  }

  .lang:hover > div {
    color: #512d7d;
    background: #fff;
  }

  .lang div:hover {
    color: #fff;
    background: #512d7d;
  }

  .lang div:first-child {
    border-left: 0;
    border-radius: 15px 0 0 15px;
    left: 0;
  }

  .logo-container {
    width: 100%;
  }

  .nosotres .historia {
    flex-direction: row;
  }

  footer {
    width: 100%;
    background: #565887;
    align-items: center;
    padding: 50px 0;
    display: flex;
  }

  footer .logo {
    width: 100%;
    padding-left: 100px;
  }

  footer .logo img {
    height: 90px;
  }

  footer .data-fiscal {
    min-width: 150px;
  }

  footer .data-fiscal img {
    height: 90px;
  }

  footer .social-links a {
    padding: 15px;
  }

  footer .social-links a img {
    width: 30px;
  }

  footer .social-links {
    height: 100%;
    min-width: 300px;
  }

  .hero {
    height: 90vh;
  }

  .social-links a:hover > img {
    opacity: .8;
  }

  .nosotres .historia {
    padding: 0 0 300px;
  }

  .nosotres .somos-cooperativa .video {
    height: 600px;
  }

  .nosotres .somos-cooperativa .text {
    flex-direction: row;
  }

  .nosotres .somos-cooperativa .text p {
    width: 48%;
  }

  .nosotres .compromiso-con-la-comunidad div {
    width: 30%;
  }

  .software-libre {
    background-color: #4b5d9f;
    background-image: url("nosotres-computer.dcf123bd.jpg");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 15px;
    margin: 0 100px 100px;
    padding: 50px;
  }

  .software-libre div {
    width: 650px;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 30px;
    line-height: 25px;
  }

  .nosotres .compromiso-con-la-comunidad .text {
    text-align: right;
  }

  .algunos-clientes .images {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .algunos-clientes {
    padding: 50px 100px;
  }

  .algunos-clientes .images div {
    width: 165px;
  }

  .servicios .grid {
    justify-content: start;
    padding-bottom: 50px;
  }

  .servicios .grid div {
    width: 25%;
    padding: 30px;
  }

  .servicios .grid div p {
    line-height: 30px;
  }

  .tecnologias .grid {
    max-width: 70%;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    margin: auto;
  }

  .tecnologias .grid div {
    width: 200px;
    font-size: 15px;
  }

  .proyectos .grid {
    color: #666;
    flex-flow: wrap;
    justify-content: center;
    display: flex;
  }

  .proyectos .grid div {
    width: 40%;
  }

  .proyectos .grid .tecnologias-usadas {
    display: block;
  }

  .proyectos .grid .proyecto-img .principal {
    width: 100%;
    padding: 0 50px;
  }

  .proyectos .grid .proyecto-img .principal img {
    width: 80%;
  }

  .proyectos .grid .plus-group {
    width: 100%;
    text-align: center;
  }

  .proyectos .grid .plus-group img {
    width: 150px;
  }

  .nosotres .somos-cooperativa .somos-cooperativa-frame {
    display: block;
  }
}

.validated input:invalid, .validated textarea:invalid {
  background: #fbb;
  border-bottom: 1px solid red;
}

input {
  box-sizing: border-box;
  color: var(--violeta);
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--primario);
  background: none;
  border-radius: 0;
  padding: .3em .1em;
}

textarea {
  box-sizing: border-box;
  color: var(--gris-oscuro);
  box-shadow: 0px 5px 4px -1px var(--sombra);
  resize: none;
  min-height: 10em;
  background-color: var(--fondo-gris);
  width: 100%;
  border: none;
  border-radius: 15px;
  margin-top: .5em;
  padding: 1em 1.5em;
}

.field {
  margin-top: 1em;
}

.input-group input:focus, textarea:focus {
  outline: none;
}

.input-group input:focus ~ label, textarea:focus ~ label, input.filled ~ label, textarea:valid ~ label {
  color: var(--primario);
  font-size: .8em;
  top: -1em;
}

input:focus ~ .bar:before, textarea:focus ~ .bar:before {
  width: 100%;
}

.input-group {
  margin-top: 1em;
  position: relative;
}

.input-group label {
  color: var(--primario);
  pointer-events: none;
  transition: all .3s;
  position: absolute;
  top: .2em;
  left: .1em;
}

.input-group .bar {
  display: block;
  position: relative;
}

.input-group .bar:before {
  content: "";
  height: 2px;
  width: 0;
  background: var(--primario);
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

input[type="range"] {
  accent-color: var(--primario);
}

input[type="range"]::-webkit-slider-thumb {
  opacity: 0;
}

input[type="range"]::-moz-range-thumb {
  opacity: 0;
}

input[type="range"]::-ms-thumb {
  opacity: 0;
}

.range-field {
  align-items: center;
  display: flex;
}

.range-field #budget_text {
  width: 30%;
  text-align: right;
}

/*# sourceMappingURL=index.1e2064c7.css.map */
